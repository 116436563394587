import { ethers } from "ethers";
import { sample } from "lodash";
import { NetworkMetadata } from "lib/wallets";
import { isDevelopment } from "./env";
import { truncate } from "fs";

const { parseEther } = ethers.utils;

export const ETH_MAINNET = 1;
export const TELOS_TESTNET = 41;
export const TELOS_MAINNET = 40;
export const BASE_MAINNET = 8453;
export const FUSE_MAINNET = 122;
export const METER_MAINNET = 82;
export const TAIKO_MAINNET = 167000;
// export const BASE_TESTNET = 84531;



// TODO take it from web3 JJ
export const DEFAULT_CHAIN_ID = TELOS_MAINNET;
export const CHAIN_ID = DEFAULT_CHAIN_ID;

export const SUPPORTED_CHAIN_IDS = [TELOS_MAINNET, BASE_MAINNET, FUSE_MAINNET, METER_MAINNET, TAIKO_MAINNET];

if (isDevelopment()) {
  SUPPORTED_CHAIN_IDS.push(TELOS_TESTNET);
}

export const IS_NETWORK_DISABLED = {
  [TELOS_TESTNET]: true,
  [BASE_MAINNET]: false,
  [TELOS_MAINNET]: false,
  [FUSE_MAINNET]: false,
  [METER_MAINNET]: false,
  [TAIKO_MAINNET]: false,

};

export const CHAIN_NAMES_MAP = {
  [TELOS_TESTNET]: "Telos Testnet",
  [TELOS_MAINNET]: "Telos Mainnet",
  [BASE_MAINNET]: "Base Mainnet",
  [FUSE_MAINNET]: "Fuse Mainnet",
  [METER_MAINNET]: "Meter Mainnet",
  [TAIKO_MAINNET]: "Taiko Mainnet",

};

export const GAS_PRICE_ADJUSTMENT_MAP = {
  [TELOS_TESTNET]: "0", // 3 gwei
  [TELOS_MAINNET]: "0", // 3 gwei
  [BASE_MAINNET]: "0", // 3 gwei
  [FUSE_MAINNET]: "0", // 3 gwei
  [METER_MAINNET]: "0", // 3 gwei
  [TAIKO_MAINNET]: "0", // 3 gwei

};

export const MAX_GAS_PRICE_MAP = {
  [TELOS_TESTNET]: "200000000000", // 200 gwei
};

export const HIGH_EXECUTION_FEES_MAP = {
  [TELOS_TESTNET]: 3, // 3 USD
  [TELOS_MAINNET]: 3, // 3 USD
  [BASE_MAINNET]: 3, // 3 USD
  [FUSE_MAINNET]: 3, // 3 USD
  [METER_MAINNET]: 3, // 3 USD
  [TAIKO_MAINNET]: 3, // 3 USD

};

const constants = {

  [TELOS_TESTNET]: {
    nativeTokenSymbol: "TLOS",
    wrappedTokenSymbol: "WTLOS",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [TELOS_MAINNET]: {
    nativeTokenSymbol: "TLOS",
    wrappedTokenSymbol: "WTLOS",
    defaultCollateralSymbol: "USDM",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },


  [BASE_MAINNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDM",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
  [TAIKO_MAINNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDM",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [FUSE_MAINNET]: {
    nativeTokenSymbol: "FUSE",
    wrappedTokenSymbol: "WFUSE",
    defaultCollateralSymbol: "USDM",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },

  [METER_MAINNET]: {
    nativeTokenSymbol: "MTR",
    wrappedTokenSymbol: "WMTR",
    defaultCollateralSymbol: "USDM",
    defaultFlagOrdersEnabled: false,
    positionReaderPropsLength: 9,
    v2: true,

    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001"),
  },
};

const ALCHEMY_WHITELISTED_DOMAINS = ["gmx.io", "app.gmx.io"];

export const RPC_PROVIDERS = {
  [ETH_MAINNET]: ["https://rpc.ankr.com/eth"],
  [TELOS_TESTNET]: ["https://testnet.telos.net/evm"],
  [TELOS_MAINNET]: ["https://mainnet.telos.net/evm"],
  [BASE_MAINNET]: ["https://1rpc.io/base"],
  [FUSE_MAINNET]: ["https://rpc.fuse.io"],
  [METER_MAINNET]: ["https://rpc.meter.io"],
  [TAIKO_MAINNET]: ["https://rpc.taiko.xyz"]
};

export const FALLBACK_PROVIDERS = {
  [TELOS_TESTNET]: ["https://testnet.telos.net/evm"],
  [TELOS_MAINNET]: ["https://rpc1.us.telos.net/evm"],
  [BASE_MAINNET]: ["https://base.meowrpc.com"],
  [FUSE_MAINNET]: ["https://fuse-pokt.nodies.app"],
  [METER_MAINNET]: ["https://meter.blockpi.network/v1/rpc/public"],
  [TAIKO_MAINNET]: ["https://taiko.blockpi.network/v1/rpc/public"]

};

export const NETWORK_METADATA: { [chainId: number]: NetworkMetadata } = {

  [TELOS_TESTNET]: {
    chainId: "0x" + TELOS_TESTNET.toString(16),
    chainName: "Telos Testnet",
    launchTime: 1705320307,
    nativeCurrency: {
      name: "TLOS",
      symbol: "TLOS",
      decimals: 18,
    },
    rpcUrls: ["https://testnet.telos.net/evm"],
    blockExplorerUrls: ["https://testnet.teloscan.io/"],
  },

  [TELOS_MAINNET]: {
    chainId: "0x" + TELOS_MAINNET.toString(16),
    chainName: "Telos Mainnet",
    launchTime: 1705320307,
    nativeCurrency: {
      name: "TLOS",
      symbol: "TLOS",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.telos.net/evm"],
    blockExplorerUrls: ["https://www.teloscan.io/"],
  },
  [BASE_MAINNET]: {
    chainId: "0x" + BASE_MAINNET.toString(16),
    chainName: "Base Mainnet",
    launchTime: 1705320307,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://base.meowrpc.com"],
    blockExplorerUrls: ["https://basescan.org/"],
  },
  [TAIKO_MAINNET]: {
    chainId: "0x" + BASE_MAINNET.toString(16),
    chainName: "Taiko Mainnet",
    launchTime: 1705320307,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.taiko.xyz"],
    blockExplorerUrls: ["https://taikoscan.io/"],
  },
  [FUSE_MAINNET]: {
    chainId: "0x" + FUSE_MAINNET.toString(16),
    chainName: "Fuse Mainnet",
    launchTime: 1708517107,
    nativeCurrency: {
      name: "FUSE",
      symbol: "FUSE",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.fuse.io"],
    blockExplorerUrls: ["https://explorer.fuse.io/"],
  },
  [METER_MAINNET]: {
    chainId: "0x" + METER_MAINNET.toString(16),
    chainName: "Meter Mainnet",
    launchTime: 1711111636,
    nativeCurrency: {
      name: "MTR",
      symbol: "MTR",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io//"],
  },

};

export const CHAIN_ICONS = {

  [TELOS_TESTNET]: {
    16: "ic_tlos_16.svg",
    24: "ic_tlos_24.svg",
    96: "ic_tlos_96.svg",
  },

  [TELOS_MAINNET]: {
    16: "ic_tlos_16.svg",
    24: "ic_tlos_24.svg",
    96: "ic_tlos_96.svg",
  },

  [BASE_MAINNET]: {
    16: "ic_base_16.svg",
    24: "ic_base_24.svg",
    96: "ic_base_96.svg",
  },

  [FUSE_MAINNET]: {
    16: "ic_fuse_16.svg",
    24: "ic_fuse_24.svg",
    96: "ic_fuse_96.svg",
  },

  [METER_MAINNET]: {
    16: "ic_meter_16.svg",
    24: "ic_meter_24.svg",
    96: "ic_meter_96.svg",
  },

  [TAIKO_MAINNET]: {
    16: "ic_taiko_16.svg",
    24: "ic_taiko_24.svg",
    96: "ic_taiko_96.svg",
  },
};

export const getConstant = (chainId: number, key: string) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }

  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }

  return constants[chainId][key];
};

export function getChainName(chainId: number) {
  return CHAIN_NAMES_MAP[chainId];
}

export function getChainIcon(chainId: number, size: 16 | 24 | 96 | 41): string | undefined {
  return CHAIN_ICONS[chainId]?.[size];
}

export function getDefaultArbitrumRpcUrl() {
  return "https://arb1.arbitrum.io/rpc";
}

export function getRpcUrl(chainId: number): string | undefined {
  return sample(RPC_PROVIDERS[chainId]);
}

export function getFallbackRpcUrl(chainId: number): string | undefined {
  return sample(FALLBACK_PROVIDERS[chainId]);
}

export function getAlchemyHttpUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getAlchemyWsUrl() {
  if (ALCHEMY_WHITELISTED_DOMAINS.includes(window.location.host)) {
    return "wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ";
  }
  return "wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2";
}

export function getExplorerUrl(chainId) {
  if (chainId === TELOS_TESTNET) {
    return "https://testnet.teloscan.io/";
  } else if (chainId === TELOS_MAINNET) {
    return "https://www.teloscan.io/";
  } else if (chainId === BASE_MAINNET) {
    return "https://basescan.org/";
  } else if (chainId === FUSE_MAINNET) {
    return "https://explorer.fuse.io/";
  } else if (chainId === METER_MAINNET) {
    return "https://scan.meter.io/";
  } else if (chainId === TAIKO_MAINNET) {
    return "https://taikoscan.io/";
  }
  return "https://etherscan.io/";
}

export function getHighExecutionFee(chainId) {
  return HIGH_EXECUTION_FEES_MAP[chainId] || 3;
}

export function isSupportedChain(chainId) {
  return SUPPORTED_CHAIN_IDS.includes(chainId);
}
